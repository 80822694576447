















































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { readUserProfile } from '@/store/main/getters';
import { readConstants } from '@/store/main/getters';
import { dispatchGetConstants } from '@/store/main/actions';
import USDTStableChart from "../../components/plan/usdt_stable.vue";
import ETHStableChart from "../../components/plan/eth_stable.vue";
import StratChart from "./strat_chart.vue";
import TokenChart from "./token_chart.vue";

Vue.component('ETHStableChart', ETHStableChart);
Vue.component('StratChart', StratChart);
Vue.component('TokenChart', TokenChart);

@Component
export default class News extends Vue {
  get historyUSDTSun() {
    const constants = readConstants(this.$store);
    if (constants) {
      return constants.APR_USDT_sun;
    }
  }
  
  async mounted() {
    await dispatchGetConstants(this.$store);
  }

  // update version
  checkVersion() {
    const constants = readConstants(this.$store);

    let versionID = "none";
    if (constants) {
      versionID = constants.version;
    }
    // console.log('check version');
    // console.log('current version: ' + versionID);

    if (versionID !== "none" && localStorage.getItem('vers') !== versionID) {
      // console.log('RELOADDDDDDDDDDDDDDD');
      localStorage.setItem('vers', versionID);
      window.location.reload();
    }
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.checkVersion();
  }

  onResize() {
    this.isMobile = window.innerWidth < 960;
  }
}


